<template>
    <div>
        <b-row>
            <b-col cols="12">
                <b-card class="my-card m-0">
                    <b-table 
                    striped
                    small
                    id="myTables"
                    bordered
                    selectable
                    selected-variant="primary"
                    select-mode="single"
                    responsive="sm"
                    :items="rating"
                    :fields="fields"
                    head-row-variant="primary"
                    >
                    <template #cell(checkbox)="row">
                        <b-form-checkbox
                        v-model="row.detailsShowing"
                        plain
                        class="vs-checkbox-con"
                        @change="row.toggleDetails"
                        >
                        </b-form-checkbox>
                    </template>
                </b-table>
            </b-card>
        </b-col>
        </b-row>
    </div>
</template>

<script>
import { resizeable } from "@/utils/TableFunctions/resizeable.js";
export default {
    data() {
        return {
            fields: [
                { key: 'checkbox', label: '', thStyle: {width: '30px'}},
                { key: 'performer_login', label: 'Логин', sortable: true, thStyle: {width: '65px'}},
                { key: 'performer_rating_worked_at', label: 'Дата обновления рейтинга', sortable: true, thStyle: {width: '220px'}},
                { key: 'orders_success', label: 'Выполненные заказы', sortable: true, thStyle: {width: '185px'}},
                { key: 'orders_auto_assignment', label: 'Выполненные заказы по автоназначению', sortable: true, thStyle: {width: '338px'}},
                { key: 'without_contacting_the_operator', label: 'Выполненные заказы без обращения к оператору', sortable: true, thStyle: {width: '390px'}},
                { key: 'at_rush_hour', label: 'Выполненные заказы в часы пик', sortable: true, thStyle: {width: '270px'}},
                { key: 'completed_on_time', label: 'Выполненные заказы на время', sortable: true, thStyle: {width: '255px'}},
                { key: 'orders_in_day', label: 'Выполненных заказов за день', sortable: true, thStyle: {width: '250px'}},
                { key: 'order_with_priority', label: 'Выполненных приоритетных заказов', sortable: true, thStyle: {width: '305px'}},
                { key: 'waiver_from_performer', label: 'Отказы по инициативе водителя', sortable: true, thStyle: {width: '265px'}},
                { key: 'refusal_from_auto_assignment', label: 'Отказы от автоназначения', sortable: true, thStyle: {width: '225px'}},
                { key: 'late_cancellation', label: 'Поздние отказы от предварительных', sortable: true, thStyle: {width: '105px'}},
                { key: 'disturbance', label: 'Нарушений', sortable: true, thStyle: {width: '70px'}},
                { key: 'client_put_one_ball', label: '1 балл', sortable: true, thStyle: {width: '80px'}},
                { key: 'client_put_two_ball', label: '2 балла', sortable: true, thStyle: {width: '80px'}},
                { key: 'client_put_three_ball', label: '3 балла', sortable: true, thStyle: {width: '80px'}},
                { key: 'client_put_four_ball', label: '4 балла', sortable: true, thStyle: {width: '80px'}},
                { key: 'client_put_five_ball', label: '5 баллов', sortable: true, thStyle: {width: '90px'}},
            ],
            rating: [],
            showPreloader: false
        }
    },
    methods: {
    },
    mounted(){
        this.$store.commit('pageData/setdataCount', null)
        resizeable()
        this.showPreloader = true
        this.$http
            .get(`rating/by-performer/${this.$route.query.filter}`)
            .then(res => {
                this.rating = res.data
                this.showPreloader = false
                this.$store.commit('pageData/setdataCount', this.rating.length)
            })
    },
    watch: {
        '$route.query.filter': function(newPage, oldPage) {
            if (newPage !== oldPage) {
            this.showPreloader = true
            this.$http
                .get(`rating/by-performer/${this.$route.query.filter}`)
                .then(res => {
                    this.rating = res.data
                    this.showPreloader = false
                    this.$store.commit('pageData/setdataCount', this.rating.length)
                })
            }
        }
    }
}
</script>

<style lang="scss" scoped>
@import '@core/scss/vue/libs/vue-context.scss';
.my-card .card-body{
    padding: 0px;
}
.my-card{
overflow: auto; 
height: calc(100vh - 92px);
}
@media (max-height: 700px) {
  .my-card{
    overflow: auto; 
    height: 530px;
    }
}
table{
    outline: none; 
    table-layout: fixed;
    overflow: hidden;
    position: relative;
}
tr, td, th{
    padding: 0;
}
td, th{ 
    width: 100px; 
    white-space: nowrap; 
    overflow: hidden;  
    text-align: left; 
    box-sizing: border-box; 
    min-width: 60px;
    } 
</style> 